import store from '../store/'
import {Actions, Features} from './consts'

const notFoundComponent = () => import('@/pages/NotFoundComponent.vue')
const AuthLayout = () => import('@/pages/Dashboard/Pages/AuthLayout.vue')
const Login = () => import('@/pages/Dashboard/Pages/Login.vue')

const DashboardLayout = () =>
    import('@/pages/Dashboard/Layout/DashboardLayout.vue')
const Dashboard = () => import('@/pages/Dashboard/Dashboard.vue')

const BridgesList = () => import('@/pages/Dashboard/Bridges')
const BridgeProfileForm = () =>
    import('@/pages/Dashboard/Bridges/bridgeProfileForm')
const BridgeConditionForm = () =>
    import('@/pages/Dashboard/Bridges/bridgeConditionForm')
const BridgeInventoryForm = () =>
    import('@/pages/Dashboard/Bridges/bridgeInventoryForm')
const BridgeInterventionForm = () =>
    import('@/pages/Dashboard/Bridges/bridgeInterventionForm')
const BridgeFiles = () => import('@/pages/Dashboard/Bridges/files')
const Inspections = () => import('@/pages/Dashboard/Components/Inspections')

const Disasters = () => import('@/pages/Dashboard/Disasters')
const DisasterProfileForm = () =>
    import('@/pages/Dashboard/Disasters/profileForm.vue')


const UnitCosts = () => import('@/pages/Dashboard/UnitCosts')
//const UnitCosts = () => import('@/pages/Dashboard/UnitCosts/list')
const UnitCostsProfileForm = () =>
    import('@/pages/Dashboard/UnitCosts/mucProfileForm')
const UnitCostsWorks = () => import('@/pages/Dashboard/UnitCosts/mucWorkList')
const UnitCostsWorksProfileForm = () =>
    import('@/pages/Dashboard/UnitCosts/mucWorkProfileForm')
const UnitCostsWorksCriteria = () =>
    import('@/pages/Dashboard/UnitCosts/mucWorkCriteriaList')
const UnitCostsWorksCriterionProfileForm = () =>
    import('@/pages/Dashboard/UnitCosts/mucWorkCriterionProfileForm')

const Subnetworks = () => import('@/pages/Dashboard/Subnetworks')
const SubnetworkProfileForm = () =>
    import('@/pages/Dashboard/Subnetworks/snProfileForm')

const Roads = () => import('@/pages/Dashboard/Roads')
const RoadProfileForm = () =>
    import('@/pages/Dashboard/Roads/profileForm.vue')


const Plans = () => import('@/pages/Dashboard/Plans')
const PlansProfileForm = () => import('@/pages/Dashboard/Plans/planProfileForm')
const PlansElementList = () => import('@/pages/Dashboard/Plans/planElementList')

const Translate = () => import('@/pages/Dashboard/Translate')
const TranslateProfileForm = () =>
    import('@/pages/Dashboard/Translate/translateProfileForm.vue')

const Webgis = () => import('@/pages/Dashboard/Webgis')
const Manuals = () => import('@/pages/Dashboard/Manuals')
const Reports = () => import('@/pages/Dashboard/Reports')
const Permissions = () => import('@/pages/Dashboard/Permissions.vue')
const Roles = () => import ('@/pages/Dashboard/Roles')
const Users = () => import ('@/pages/Dashboard/Users')
const UserProfileForm = () => import('@/pages/Dashboard/Users/UserProfileForm')

const MeProfileForm = () => import('@/pages/Dashboard/Users/MeProfileForm')

const checkLocales = async (to, from, next) => {
    try {
        if (!store.getters.localesListLoaded) {
            await store.dispatch('LOAD_LOCALES_LIST')
        }
        next()
    } catch (err) {
        console.log('checkLocales', err)
        next('/server-error')
    }
}

const userLogout = async (to, from, next) => {
    await store.dispatch('LOGOUT')
    next('/log-in')
}

const loginEvent = async (to, from, next) => {
    try {
        const base64token = to.query.token
        if (!base64token) {
            next('/log-in')
            return
        }

        const token = Buffer.from(base64token, 'base64')

        await store.dispatch('LOGIN_STT', token)
        next("/dashboard")

    } catch (err) {
        console.log('loginEvent', err)
    }
}

const checkLoginLangMessage = async (to, from, next) => {
    const actLang = store.getters.locale_active
    try {
        await store.dispatch('LOAD_LOGIN_TRANSLATE', actLang)
        next()
    } catch (err) {
        next('/server-error')
    }
}

const checkAccess = async (to, from, next) => {
    const {permissions} = store.state.Login.me
    const {feature, action} = to.meta
    // if the feature and action are in the permission list
    if (permissions.some(item => item.feature === feature && item.action === action)) {
        next()
    } else {
        console.log('checkAccess - no permit')
        next('/page-not-found')
    }
}

const checkDomain = (to, from, next) => {
    if (!['disasters', 'bams'].includes(to.params.domain)) {
        next({name: 'page-not-found'})
    }
    next()
}


const checkAuth = async (to, from, next) => {
    if (store.getters.hasToken) {
        store.commit('SET_HEADER_AUTH')
        try {
            if (!store.getters.profileLoaded) {
                await store.dispatch('GET_MY_PROFILE')
            }

            if (!store.getters.appOptionsLoaded) {
                await store.dispatch('LOAD_APP_OPTIONS')
            }

            const actLang = store.getters.locale_active
            if (!store.getters.isUiTranslateLoaded) {
                await store.dispatch('LOAD_UI_TRANSLATE', actLang)
            }
            next()
        } catch (err) {
            //next('/log-in')
        }
    } else {
        store.commit('SET_REDIRECT_AFTER_LOGIN', to.fullPath)
        next('/log-in')
    }
}

let authPages = {
    path: '/',
    component: AuthLayout,
    name: 'Authentication',
    beforeEnter: checkLocales,
    children: [
        {
            path: '/log-in',
            name: 'Login',
            component: Login,
            beforeEnter: checkLoginLangMessage
        },
        {
            path: '/log-in-event',
            name: 'login-event',
            beforeEnter: loginEvent
        },
        {
            path: '/logout',
            name: 'Logout',
            beforeEnter: userLogout
        }
    ]
}

const index = [
    // {
    //   path: '/',
    //
    // },
    {
        path: '/',
        component: DashboardLayout,
        beforeEnter: checkLocales,
        redirect: '/dashboard',
        children: [
            {
                path: '/dashboard',
                name: 'dashboard',
                component: Dashboard,
                meta: {
                    middleware: [checkAuth],
                    hideFooter: true
                },
                props: true
            },
            {path: '/:domain/reports', redirect: '/:domain/reports/list'},
            {
                path: '/:domain/reports/:report_type',
                name: 'reports',
                component: Reports,
                meta: {
                    middleware: [checkDomain, checkAuth],
                    hideFooter: true
                },
                props: true
            },
            {
                path: '/bridges-list',
                name: 'bridges-list',
                component: BridgesList,
                meta: {
                    middleware: [checkAuth],
                    hideFooter: true
                },
                props: true
            },
            {
                path: '/bridge-data/add',
                name: 'bridge-add',
                component: BridgeProfileForm,
                meta: {
                    middleware: [checkAuth, checkAccess],
                    hideFooter: true,
                    feature: Features.bridge,
                    action: Actions.create
                },
                props: {oper: 'add'}
            },
            {
                path: '/bridge-data/upd/:bid',
                name: 'bridge-upd',
                component: BridgeProfileForm,
                meta: {
                    middleware: [checkAuth, checkAccess],
                    hideFooter: true,
                    feature: Features.bridge,
                    action: Actions.update
                },
                props: {oper: 'upd'}
            },
            {
                path: '/bridge-data/condition/:bid',
                name: 'bridge-condition',
                component: BridgeConditionForm,
                meta: {
                    middleware: [checkAuth],
                    hideFooter: true,
                },
                props: true
            },
            {
                path: '/bridge-data/condition/:aid/list',
                name: 'bridge-condition-list',
                component: Inspections,
                meta: {
                    middleware: [checkAuth],
                    hideFooter: true
                },
                props: {default: true, assetType: 'bridge', inspectionType: 'conditions', viewItemForm: "bridge-condition-view"}
            },
            {
                path: '/bridge-data/condition/:bid/history/:iid',
                name: 'bridge-condition-view',
                component: BridgeConditionForm,
                meta: {
                    middleware: [checkAuth],
                    hideFooter: true
                },
                props: {default: true, mode: "read"}
            },
            {
                path: '/bridge-data/files/:bid/',
                name: 'bridge-files',
                component: BridgeFiles,
                meta: {
                    middleware: [checkAuth, checkAccess],
                    hideFooter: true,
                    feature: Features.inspectionFiles,
                    action: Actions.create
                },
                props: {default: true}
            },
            {
                path: '/bridge-data/inventory/:bid',
                name: 'bridge-inventory',
                component: BridgeInventoryForm,
                meta: {
                    middleware: [checkAuth],
                    hideFooter: true
                },
                props: true
            },
            {
                path: '/bridge-data/inventory/:bid/history/:iid',
                name: 'bridge-inventory-view',
                component: BridgeInventoryForm,
                meta: {
                    middleware: [checkAuth],
                    hideFooter: true
                },
                props: {default: true, mode: "read"}
            },
            {
                path: '/bridge-data/inventory/:aid/list',
                name: 'bridge-inventory-list',
                component: Inspections,
                meta: {
                    middleware: [checkAuth],
                    hideFooter: true
                },
                props: {default: true, assetType: 'bridge', inspectionType: 'inventories', viewItemForm: "bridge-inventory-view"}
            },
            {
                path: '/bridge-data/intervention/:bid',
                name: 'bridge-intervention',
                component: BridgeInterventionForm,
                meta: {
                    middleware: [checkAuth],
                    hideFooter: true
                },
                props: true
            },
            {
                path: '/bridge-data/intervention/:bid/history/:iid',
                name: 'bridge-intervention-view',
                component: BridgeInterventionForm,
                meta: {
                    middleware: [checkAuth],
                    hideFooter: true
                },
                props: {default: true, mode: "read"}
            },
            {
                path: '/bridge-data/intervention/:aid/list',
                name: 'bridge-intervention-list',
                component: Inspections,
                meta: {
                    middleware: [checkAuth],
                    hideFooter: true
                },
                props: {default: true, assetType: 'bridge', inspectionType: 'interventions', viewItemForm: "bridge-intervention-view"}
            },
            {
                path: '/subnetworks',
                name: 'subnetwork-list',
                component: Subnetworks,
                meta: {
                    middleware: [checkAuth],
                    hideFooter: true
                },
                props: true
            },
            {
                path: '/subnetworks/add',
                name: 'subnetwork-add',
                component: SubnetworkProfileForm,
                meta: {
                    middleware: [checkAuth, checkAccess],
                    hideFooter: true,
                    feature: Features.subnetwork,
                    action: Actions.create
                },
                props: {oper: 'add'}
            },
            {
                path: '/subnetworks/upd/:net_id',
                name: 'subnetwork-upd',
                component: SubnetworkProfileForm,
                meta: {
                    middleware: [checkAuth, checkAccess],
                    hideFooter: true,
                    feature: Features.subnetwork,
                    action: Actions.update
                },
                props: {oper: 'upd'}
            },

            {
                path: '/roads',
                name: 'road-list',
                component: Roads,
                meta: {
                    middleware: [checkAuth],
                    hideFooter: true
                },
                props: true
            },
            {
                path: '/roads/add',
                name: 'road-add',
                component: RoadProfileForm,
                meta: {
                    middleware: [checkAuth, checkAccess],
                    hideFooter: true,
                    feature: Features.road,
                    action: Actions.create
                },
                props: {oper: 'add'}
            },
            {
                path: '/roads/upd/:road_id',
                name: 'road-upd',
                component: RoadProfileForm,
                meta: {
                    middleware: [checkAuth, checkAccess],
                    hideFooter: true,
                    feature: Features.road,
                    action: Actions.update
                },
                props: {oper: 'upd'}
            },

            {
                path: '/planning',
                name: 'plans',
                component: Plans,
                meta: {
                    middleware: [checkAuth],
                    hideFooter: true
                },
                props: true
            },
            {
                path: '/planning/add',
                name: 'plans-add',
                component: PlansProfileForm,
                meta: {
                    middleware: [checkAuth, checkAccess],
                    hideFooter: true,
                    feature: Features.plans,
                    action: Actions.create
                },
                props: {oper: 'add'}
            },
            {
                path: '/planning/upd/:pid',
                name: 'plans-upd',
                component: PlansProfileForm,
                meta: {
                    middleware: [checkAuth, checkAccess],
                    hideFooter: true,
                    feature: Features.plans,
                    action: Actions.update
                },
                props: {oper: 'upd'}
            },
            {
                path: '/planning/:pid/elements',
                name: 'plans-elements',
                component: PlansElementList,
                meta: {
                    middleware: [checkAuth],
                    hideFooter: true
                },
                props: true
            },

            {
                path: 'disasters/list',
                name: 'disasters-list',
                component: Disasters,
                meta: {
                    middleware: [checkAuth],
                    hideFooter: true
                },
                props: true
            },
            {
                path: '/disasters/add',
                name: 'disaster-add',
                component: DisasterProfileForm,
                meta: {
                    middleware: [checkAuth, checkAccess],
                    hideFooter: true,
                    feature: Features.disasters,
                    action: Actions.create
                },
                props: {oper: 'add'}
            },
            {
                path: '/disasters/upd/:id',
                name: 'disaster-upd',
                component: DisasterProfileForm,
                meta: {
                    middleware: [checkAuth, checkAccess],
                    hideFooter: true,
                    feature: Features.disasters,
                    action: Actions.update
                },
                props: {oper: 'upd'}
            },


            {
                path: '/translate',
                name: 'Translate',
                components: {default: Translate},
                meta: {
                    middleware: [checkAuth],
                    hideFooter: true
                }
            },
            {
                path: '/translate_add',
                name: 'Translate_add',
                component: TranslateProfileForm,
                meta: {
                    middleware: [checkAuth, checkAccess],
                    hideFooter: true,
                    feature: Features.localisation,
                    action: Actions.create
                },
                props: {oper: 'add'}
            },
            {
                path: '/permissions',
                name: 'Permissions',
                components: {default: Permissions},
                meta: {
                    middleware: [checkAuth, checkAccess],
                    hideFooter: true,
                    feature: Features.permissions,
                    action: Actions.list
                }
            },
            {
                path: '/roles',
                name: 'roles',
                component: Roles,
                meta: {
                    middleware: [checkAuth],
                    hideFooter: true
                }
            },
            {
                path: '/users',
                name: 'users',
                component: Users,
                meta: {
                    middleware: [checkAuth],
                    hideFooter: true
                }
            },
            {
                path: '/users/add',
                name: 'user-add',
                component: UserProfileForm,
                meta: {
                    middleware: [checkAuth, checkAccess],
                    hideFooter: true,
                    feature: Features.users,
                    action: Actions.create
                },
                props: {act: 'new'}
            },
            {
                path: '/users/:login',
                name: 'user-upd',
                component: UserProfileForm,
                meta: {
                    middleware: [checkAuth, checkAccess],
                    hideFooter: true,
                    feature: Features.users,
                    action: Actions.update
                },
                props: {act: 'upd'}
            },
            {
                path: '/me/profile',
                name: 'me-profile',
                component: MeProfileForm,
                meta: {
                    middleware: [checkAuth],
                    hideFooter: true
                },
                props: true
            },
            {
                path: 'manuals',
                name: 'Manuals',
                component: Manuals,
                meta: {
                    middleware: [checkAuth],
                    hideFooter: true
                }
            },

            {
                path: '/:domain/web-gis',
                name: 'webgis',
                component: Webgis,
                meta: {
                    middleware: [checkDomain, checkAuth],
                    hideFooter: true
                },
                props: true
            },

            {
                path: '/unit_costs',
                name: 'unit-costs',
                component: UnitCosts,
                meta: {
                    middleware: [checkAuth],
                    hideFooter: true
                }
            },
            {
                path: '/unit-costs/add',
                name: 'unit-costs-add',
                component: UnitCostsProfileForm,
                meta: {
                    middleware: [checkAuth, checkAccess],
                    hideFooter: true,
                    feature: Features.unitCost,
                    action: Actions.create
                },
                props: {oper: 'add'}
            },
            {
                path: '/unit-costs/upd/:mid',
                name: 'unit-costs-upd',
                component: UnitCostsProfileForm,
                meta: {
                    middleware: [checkAuth, checkAccess],
                    hideFooter: true,
                    feature: Features.unitCost,
                    action: Actions.update
                },
                props: {default: true, oper: 'upd'}
            },
            {
                path: '/unit-costs/:mid/works',
                name: 'unit-costs-works',
                component: UnitCostsWorks,
                meta: {
                    middleware: [checkAuth],
                    hideFooter: true,

                },
                props: {default: true}
            },
            {
                path: '/unit-costs/:mid/works/add',
                name: 'unit-costs-works-add',
                component: UnitCostsWorksProfileForm,
                meta: {
                    middleware: [checkAuth, checkAccess],
                    hideFooter: true,
                    feature: Features.unitCostWork,
                    action: Actions.create
                },
                props: {default: true, oper: 'add'}
            },
            {
                path: '/unit-costs/:mid/works/upd/:work_id',
                name: 'unit-costs-works-upd',
                component: UnitCostsWorksProfileForm,
                meta: {
                    middleware: [checkAuth, checkAccess],
                    hideFooter: true,
                    feature: Features.unitCostWork,
                    action: Actions.update
                },
                props: {default: true, oper: 'upd'}
            },
            {
                path: '/unit-costs/:mid/works/:work_id/criteria',
                name: 'unit-costs-works-criteria',
                component: UnitCostsWorksCriteria,
                meta: {
                    middleware: [checkAuth],
                    hideFooter: true,
                },
                props: true
            },
            {
                path: '/unit-costs/:mid/works/:work_id/criterion/add',
                name: 'unit-costs-works-criterion-add',
                component: UnitCostsWorksCriterionProfileForm,
                meta: {
                    middleware: [checkAuth, checkAccess],
                    hideFooter: true,
                    feature: Features.unitCostWorkCriterion,
                    action: Actions.create
                },
                props: {default: true, oper: 'add'}
            },
            {
                path: '/unit-costs/:mid/works/:work_id/criterion/upd/:crit_id',
                name: 'unit-costs-works-criterion-upd',
                component: UnitCostsWorksCriterionProfileForm,
                meta: {
                    middleware: [checkAuth, checkAccess],
                    hideFooter: true,
                    feature: Features.unitCostWorkCriterion,
                    action: Actions.update
                },
                props: {default: true, oper: 'upd'}
            },

            {
                path: 'under-construction',
                name: 'under-construction',
                component: notFoundComponent,
                meta: {
                    target: 'page-not-found',
                    hideFooter: true,
                    middleware: [checkAuth]
                }
            },
            {
                path: 'page-not-found',
                name: 'page-not-found',
                component: notFoundComponent,
                meta: {
                    target: 'page-not-found',
                    hideFooter: true,
                    middleware: [checkAuth]
                }
            }
        ]
    },
    authPages,
    {
        path: '/server-error',
        component: {
            render(h) {
                return h('div', 'Some Server Error !!!')
            }
        }
    },
    {
        path: '*',
        redirect: 'page-not-found',
        beforeEnter: checkAuth
    }
]


export default index
