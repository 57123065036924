/** ----------- AUTH -----------------------*/
import {type_json} from '@/api/utils'

export default Api => ({
    getApiBaseUrl() {
        return Api.defaults.baseURL
    },
    /**
     * Login
     * @param {string} username // user name
     * @param {string} password // password
     * @returns {Object} - 200
     * @returns {string} token
     * @throws Error
     */
    async login(data) {
        const {login: name, password: pass} = data
        return Api.post(`/auth/login`, {name, pass}, {
            headers: {
                ...type_json
            }
        })
    },


    /**
     * Login by short time token
     * @param {string} stt // short time token
     * @returns {Object} - 200
     * @returns {string} token
     * @throws Error
     */
    async loginStt(stt) {
        Api.defaults.headers['Authorization'] = `Bearer ${stt}`
        return Api.post(`/auth/login-stt`,  {
            headers: {
                ...type_json
            }
        })
    },

    /**
     * Set user password
     * @param {string} password // password
     * @returns {Object} - 204
     * @throws Error
     */
    async set_my_password(password) {
        return Api.put(`/me/set-password`, {password}, {
            headers: {
                ...type_json
            }
        })
    },

    /**
     * Send user password reset request
     * @param {string} email - Email
     * @param {string} locale - Locale
     * @returns {Object} - 200
     * @throws Error
     */
    async password_reset_request(payload) {
        const {email, locale} = payload
        return Api.post(`/password-reset-request`, {email, locale}, {
            headers: {
                ...type_json
            }
        })
    },

    logout() {
        //return Api.post(`/auth/logout`)
    }
})